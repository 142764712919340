<i18n>
  {
    "es": {
      "SAVE": 'Guardar',
      "SAVING": 'Guardando...',
      "SAVE_CARD": 'Guardar tarjeta',
      "CANCEL": 'Cancelar',
      "REQUIRED_FIELD": "* Campo requerido",
      "CARD_DETAILS": "Detalles de tarjeta",
      "CREDIT_OR_DEBIT_CARD": "Tarjeta de credito o debito",
      "CREDIT_OR_DEBIT_CARD_INFO": "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis",
      "ALIAS_PLACEHOLDER": "Alias",
      "OWNER_PLACEHOLDER": "Titular",
      "PAN_PLACEHOLDER": "Nº Tarjeta",
      "MONTH_PLACEHOLDER": "Expira (MM)",
      "YEAR_PLACEHOLDER": "Expira (YY)",
      "ADD_CARD": "Añadir tarjeta",
      "CREDITCARD_ADDED_SUCCEED" : "Tarjeta añadida correctamente",
      "CVV2": "CVV2",
      "ALIAS_REQUIRED": "El alias es obligatorio",
      "ALIAS_LENGTH": "El alias debe tener al menos 3 caracteres",
      "OWNER_REQUIRED": "El titular es obligatorio",
      "OWNER_LENGTH": "El titular debe tener al menos 3 caracteres",
      "MONTH_REQUIRED": "El mes es obligatorio",
      "MONTH_NOT_VALID": "El mes no es válido",
      "YEAR_REQUIRED": "El año es obligatorio",
      "YEAR_NOT_VALID": "El año no es válido"
    },
    "en": {
      "SAVING": 'Saving...',
      "SAVE": 'Save',
      "SAVE_CARD": 'Save card',
      "CANCEL": 'Cancel',
      "REQUIRED_FIELD": "* Required field",
      "CARD_DETAILS": "Card details",
      "CREDIT_OR_DEBIT_CARD": "Credit or debit card",
      "CREDIT_OR_DEBIT_CARD_INFO": "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis",
      "ALIAS_PLACEHOLDER": "Alias",
      "OWNER_PLACEHOLDER": "Owner",
      "PAN_PLACEHOLDER": "Card Number",
      "MONTH_PLACEHOLDER": "Expires (MM)",
      "YEAR_PLACEHOLDER": "Expires (YY)",
      "ADD_CARD": "Add card",
      "CREDITCARD_ADDED_SUCCEED" : "Card added successfully",
      "CVV2": "CVV2",
      "ALIAS_REQUIRED": "Alias is required",
      "ALIAS_LENGTH": "Alias must be at least 3 characters long",
      "OWNER_REQUIRED": "Owner is required",
      "OWNER_LENGTH": "Owner must be at least 3 characters long",
      "MONTH_REQUIRED": "Month is required",
      "MONTH_NOT_VALID": "Month is not valid",
      "YEAR_REQUIRED": "Year is required",
      "YEAR_NOT_VALID": "Year is not valid"
    }
  }
</i18n>

<template>
  <form
    ref="form"
    id="paycometPaymentForm"
    method="POST"
    action="javascript:handleSubmit();"
  >
    <div class="space-y-4">
      <div
        v-if="!formReady"
        class="w-full lg:w-[480px] h-[60px] rounded-md bg-[#d8d8d8]"
      ></div>
      <LabelAnimationInput
        v-else
        v-model="alias"
        ref="aliasInput"
        :autofocus="true"
        required
        id="alias"
        type="text"
        name="alias"
        autocomplete="alias"
        :placeholder="'Alias*'"
        @change="v$.alias.$touch()"
        :valid="!v$.alias.$invalid || alias == ''"
      />

      <div
        v-if="!formReady"
        class="w-full lg:w-[480px] h-[60px] rounded-md bg-[#d8d8d8]"
      ></div>
      <LabelAnimationInput
        v-else
        v-model="ccholder"
        required
        data-paycomet="cardHolderName"
        id="ccholder"
        type="text"
        name="ccholder"
        :placeholder="'Titular*'"
        @change="v$.ccholder.$touch()"
        :valid="!v$.ccholder.$invalid || ccholder == ''"
      />

      <div
        v-show="!formReady"
        class="w-full lg:w-[480px] h-[60px] rounded-md bg-[#d8d8d8]"
      ></div>

      <div v-show="formReady">
        <div
          id="paycomet-pan"
          data-paycomet="paNumber"
          style="
            padding: 9px 4px 9px 4px;
            height: 60px;
            border: 1px solid #ddd;
            border-radius: 5px;
          "
        ></div>

        <input
          required
          type="text"
          id="oldpan"
          name="oldpan"
          :placeholder="'Número de tarjeta*'"
          paycomet-style="width: 100%; height: 31px; font-size:14px; border:0px; outline: 2px solid transparent; outline-offset: 2px;"
          paycomet-name="pan"
        />
      </div>

      <div class="flex items-center justify-center space-x-2">
        <div
          v-if="!formReady"
          class="w-1/3 md:w-full h-[60px] rounded-md bg-[#d8d8d8]"
        ></div>
        <LabelAnimationInput
          v-else
          :smallText="smallText"
          divClass="w-full"
          required
          type="tel"
          pattern="[0-9]{1,2}"
          min="1"
          max="12"
          maxlength="2"
          size="2"
          data-paycomet="dateMonth"
          id="month"
          name="month"
          :placeholder="t('MONTH_PLACEHOLDER')"
          v-model="month"
          @change="v$.month.$touch()"
          :valid="!v$.month.$invalid || month == ''"
        />
        <div
          v-if="!formReady"
          class="w-1/3 md:w-full h-[60px] rounded-md bg-[#d8d8d8]"
        ></div>
        <LabelAnimationInput
          v-else
          :smallText="smallText"
          divClass="w-full"
          required
          type="tel"
          pattern="[0-9]{2}"
          min="22"
          max="99"
          maxlength="2"
          size="2"
          data-paycomet="dateYear"
          id="year"
          name="year"
          :placeholder="t('YEAR_PLACEHOLDER')"
          v-model="year"
          @change="v$.year.$touch()"
          :valid="!v$.year.$invalid || year == ''"
        />
        <div
          v-show="!formReady"
          class="w-1/3 md:w-7/12 h-[60px] rounded-md bg-[#d8d8d8]"
        ></div>

        <div v-show="formReady">
          <div
            class="w-full"
            id="paycomet-cvc2"
            data-paycomet="cvc2"
            style="
              padding: 6px 4px;
              height: 60px;
              border: 1px solid #ddd;
              border-radius: 5px;
            "
          ></div>

          <input
            required
            type="text"
            paycomet-placeholder="CVV2"
            paycomet-name="cvc2"
            paycomet-style="width: 100%; height: 40px; border:0px; font-size:12px"
          />
        </div>
      </div>
      <div id="paymentErrorMsg"></div>
      <div
        v-if="!formReady"
        class="w-full h-[60px] rounded-md bg-[#d8d8d8]"
      ></div>
      <button
        v-else
        :disabled="!enabledButton"
        :class="{ 'opacity-50': !enabledButton }"
        type="submit"
        class="uppercase w-full bg-others-black bg-opacity-90 text-white animation-get-light rounded h-14 font-bold text-sm transition-all duration-200 flex items-center justify-center"
      >
        <img
          v-if="saving"
          src="@/assets/svg/icons/icons-loading.svg"
          class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
        />

        <span v-if="saving">{{ t('SAVING') }}</span>
        <span v-else>{{ t('SAVE_CARD') }}</span>
      </button>

      <input type="hidden" data-paycomet="jetID" :value="jetID" />
      <input type="hidden" name="terminalId" :value="terminalId" />
    </div>
  </form>
</template>

<script setup>
import { useVuelidate } from '@vuelidate/core'
import { required, minLength, or, helpers } from '@vuelidate/validators'
const props = defineProps({
  visibility: Boolean,
  sessionId: String,
  saving: Boolean,
})

const { t, locale } = useI18n()

useHead({
  link: [
    {
      rel: 'prefetch',
      as: 'script',
      href: `https://api.paycomet.com/gateway/paycomet.jetiframe.js?lang=${locale.value}`,
    },
  ],
})

const { addMessage, addFlashMessage } = useSnackBar()

const formReady = ref(false)
const alias = ref('')
const ccholder = ref('')
const month = ref('')
const year = ref('')
const form = ref()
const smallText = ref(true)

const runtimeConfig = useRuntimeConfig()

//TODO Soportar varios terminales
const terminalId = runtimeConfig.public.PAYCOMET_TERMINAL_ID
const jetID = runtimeConfig.public.PAYCOMET_TERMINAL_JET_ID

const emit = defineEmits(['newCreditCard', 'ready', 'close', 'submit'])

const rules = computed(() => {
  return {
    alias: {
      required: helpers.withMessage(t('ALIAS_REQUIRED'), required),
      minLength: helpers.withMessage(t('ALIAS_LENGTH'), minLength(3)),
    },
    ccholder: {
      required: helpers.withMessage(t('OWNER_REQUIRED'), required),
      minLength: helpers.withMessage(t('OWNER_LENGTH'), minLength(3)),
    },
    month: {
      required: helpers.withMessage(t('MONTH_REQUIRED'), required),
      shouldBeChecked: helpers.withMessage(t('MONTH_NOT_VALID'), (value) =>
        or(...Array.from({ length: 12 }, (_, i) => i + 1))
      ),
    },
    year: {
      required: helpers.withMessage(t('YEAR_REQUIRED'), required),
      shouldBeChecked: helpers.withMessage(t('YEAR_NOT_VALID'), (value) =>
        or(
          ...Array.from({ length: 20 }, (_, i) => i + new Date().getFullYear())
        )
      ),
    },
  }
})

const v$ = useVuelidate(rules, { alias, ccholder, month, year })

const enabledButton = computed(() => {
  v$.value.$validate()
  return !v$.value.$error && formReady.value
})

const PAYCOMET_SCRIPT_ID = 'paycometScript'

onMounted(() => {
  initForm()

  window.handleSubmit = () => {
    v$.value.$validate()
    if (v$.value.$error) {
      const firstError = v$.value.$errors[0]
      addMessage({
        type: 'error',
        result: 'ERROR',
        text: firstError.$message,
      })
      return
    }

    emit('submit', {
      alias: alias.value,
      ccholder: ccholder.value,
      token: form.value.paytpvToken.value,
    })
  }
})

onUnmounted(() => {
  closeForm()
})

const initForm = async () => {
  const script = document.getElementById(PAYCOMET_SCRIPT_ID)
  if (script) {
    return
  }

  const paycometScript = document.createElement('script')
  paycometScript.setAttribute(
    'src',
    `https://api.paycomet.com/gateway/paycomet.jetiframe.js?lang=${locale.value}`
  )
  paycometScript.setAttribute('id', PAYCOMET_SCRIPT_ID)
  paycometScript.setAttribute('defer', '')
  paycometScript.onload = async function () {
    await new Promise((resolve) => setTimeout(resolve, 500))
    formReady.value = true
  }
  document.head.appendChild(paycometScript)
}

const closeForm = () => {
  const script = document.getElementById(PAYCOMET_SCRIPT_ID)
  if (script) {
    document.head.removeChild(script)
  }
  const pan = document.getElementById('paycomet-pan')
  if (pan && pan.firstChild) {
    pan.removeChild(pan.firstChild)
  }

  const cvc2 = document.getElementById('paycomet-cvc2')
  if (cvc2 && cvc2.firstChild) {
    cvc2.removeChild(cvc2.firstChild)
  }
}
</script>
