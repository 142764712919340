<i18n>
  {
    "es": {
      "SAVE": 'Guardar',
      "SAVING": 'Guardando...',
      "SAVE_CARD": 'Guardar tarjeta',
      "CANCEL": 'Cancelar',
      "CARD_DETAILS": "Detalles de tarjeta",
      "CREDIT_OR_DEBIT_CARD": "Tarjeta de credito o debito",
      "CREDIT_OR_DEBIT_CARD_INFO": "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis",
      "ALIAS_PLACEHOLDER": "Alias",
      "OWNER_PLACEHOLDER": "Titular",
      "PAN_PLACEHOLDER": "Nº Tarjeta",
      "MONTH_PLACEHOLDER": "Expira (MM)",
      "YEAR_PLACEHOLDER": "Expira (YY)",
      "ADD_CARD": "Añadir tarjeta",
      "CREDITCARD_ADDED_SUCCEED" : "Tarjeta añadida correctamente",
      "CVV2": "CVV2",
      "SECURE_DATA": "Sus datos estan seguros. Paycomet es una plataforma PCI-DSS Nivel 1, la certificación más avanzada, que encripta los datos de tarjeta",
    },
    "en": {
      "SAVING": 'Saving...',
      "SAVE": 'Save',
      "SAVE_CARD": 'Save card',
      "CANCEL": 'Cancel',
      "CARD_DETAILS": "Card details",
      "CREDIT_OR_DEBIT_CARD": "Credit or debit card",
      "CREDIT_OR_DEBIT_CARD_INFO": "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis",
      "ALIAS_PLACEHOLDER": "Alias",
      "OWNER_PLACEHOLDER": "Owner",
      "PAN_PLACEHOLDER": "Card Number",
      "MONTH_PLACEHOLDER": "Expires (MM)",
      "YEAR_PLACEHOLDER": "Expires (YY)",
      "ADD_CARD": "Add card",
      "CREDITCARD_ADDED_SUCCEED" : "Card added successfully",
      "CVV2": "CVV2",
      "SECURE_DATA": "Your data is secure. Paycomet is a PCI-DSS Level 1 platform, the most advanced certification, which encrypts card data",
    }
  }
</i18n>

<template>
  <ModalCourtain :visibility="visibility" @close="emit('close')">
    <template v-slot:title>
      <div class="flex flex-col items-center justify-center w-full bg-white">
        <h2 class="px-8 text-2xl text-others-black text-center">
          {{ t('CARD_DETAILS') }}
        </h2>
      </div>
    </template>
    <template v-slot:content>
      <div class="w-full h-full">
        <div class="md:mt-12 px-4 mx-auto max-w-lg">
          <ModalCreditCardForm
            v-if="visibility"
            :sessionId="sessionId"
            :saving="saving"
            @close="emit('close')"
            @newCreditCard="emit('newCreditCard')"
            @submit="formSubmit"
          />
          <div class="mt-4 flex items-center justify-center">
            <img
              src="@/assets/svg/icons/black/icons-lock.svg"
              class="w-2 h-2"
            />
            <p
              class="text-xs tracking-wider leading-5 md:leading-6 text-center px-2 md:px-5 text-[#717179] font-medium"
            >
              {{ t('SECURE_DATA') }}
            </p>
          </div>
          <div
            class="flex justify-center items-center px-4 space-x-4 mt-2 md:mt-4"
          >
            <img
              src="@/assets/img/banco-espana.webp"
              class="w-14 h-6 md:w-20 md:h-8"
            />
            <img
              src="@/assets/svg/icons/grey/icons-pci.svg"
              class="h-10 w-16 md:h-10 md:w-20"
            />
            <img
              src="@/assets/svg/icons/grey/icons-paycomet.svg"
              class="h-10 w-16 md:h-10 md:w-20"
            />
          </div>
        </div>
      </div>
    </template>
  </ModalCourtain>
</template>

<script setup>
const props = defineProps({
  visibility: Boolean,
  saving: Boolean,
})

const { t, locale } = useI18n()

useHead({
  link: [
    {
      rel: 'prefetch',
      as: 'script',
      href: `https://api.paycomet.com/gateway/paycomet.jetiframe.js?lang=${locale.value}`,
    },
  ],
})

const emit = defineEmits(['close', 'newCreditCard', 'submitForm'])

const formSubmit = async (data) => {
  const { alias: ccAlias, ccholder: holder, token: ccToken } = data

  emit('submitForm', { ccAlias, holder, ccToken })
}
</script>
